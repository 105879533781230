/*
BUSCADOR DE COOPERADO
Author: IBOPEdtm
Coder: Makoto Watanabe
*/
@charset "utf-8";
@import url("../../assets/fonts/fonts.css");

html,
body,
#root {
    width: 100%;
}

:root {
    --primary: #002846;
    --secondary-red: #c83c46;
    --secondary-pink: #ff6976;
}
/*Feito adaptação para o login ocupar 100% da tela sem interferir nas outras paginas*/
.container-signin {
    position: absolute;
    height: 100%;
    width: 100%;
}
.container-signin {
    font-family: "TT NormsPro";
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f5f5f5;
    height: 100%;
}

.form-signin {
    width: 100%;
    max-width: 300px;
    padding: 15px;
    margin: 0 auto;
}

.imagezinha {
    width: 100%;
    max-width: 200px;
    padding: 15px;
    margin: 0 auto;
}

.form-signin .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
}
.form-signin .form-control:focus {
    z-index: 2;
    border-color: var(--secondary-pink);
    box-shadow: 0 0 0 0.2rem rgba(255, 105, 118, 0.2);
}
.form-signin input[type="text"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.btn-coop {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}
.btn-coop:hover,
.btn-coop:active {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}
.btn-coop:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 105, 118, 0.2);
}
