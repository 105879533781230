@font-face {
font-family: 'TT NormsPro';
src: url('TTNormsProRegular.woff2') format('woff2'),
url('TTNormsProRegular.woff') format('woff');
font-weight: normal;
font-style: normal;
font-display: swap;
}

@font-face {
font-family: 'TT NormsPro';
src: url('TTNormsProBold.woff2') format('woff2'),
url('TTNormsProBold.woff') format('woff');
font-weight: bold;
font-style: normal;
font-display: swap;
}

@font-face {
font-family: 'TT NormsPro';
src: url('TTNormsProThin.woff2') format('woff2'),
url('TTNormsProThin.woff') format('woff');
font-weight: 100;
font-style: normal;
font-display: swap;
}

@font-face {
font-family: 'TT NormsPro';
src: url('TTNormsProLight.woff2') format('woff2'),
url('TTNormsProLight.woff') format('woff');
font-weight: 300;
font-style: normal;
font-display: swap;
}

@font-face {
font-family: 'TT NormsPro';
src: url('TTNormsProMedium.woff2') format('woff2'),
url('TTNormsProMedium.woff') format('woff');
font-weight: 500;
font-style: normal;
font-display: swap;
}

@font-face {
font-family: 'TT NormsPro';
src: url('TTNormsProExtraBold.woff2') format('woff2'),
url('TTNormsProExtraBold.woff') format('woff');
font-weight: 800;
font-style: normal;
font-display: swap;
}

@font-face {
font-family: 'TT NormsPro';
src: url('TTNormsProBlack.woff2') format('woff2'),
url('TTNormsProBlack.woff') format('woff');
font-weight: 900;
font-style: normal;
font-display: swap;
}