/*
BUSCADOR DE COOPERADO
Author: IBOPEdtm
*/

@charset "utf-8";
@import url("../../assets/fonts/fonts.css");

:root {
	--primary: #002846;
	--secondary-red: #c83c46;
	--secondary-pink: var(--secondary-red);
}

body,
.popover {
	font-family: "TT NormsPro";
	font-size: 0.95rem;
	line-height: 1.2rem;
}

.center {
	align-items: center;
}

.space-between {
	justify-content: space-between;
}

a {
	outline: none;
}

/*------------------------------------------------------------------
GENERAL
------------------------------------------------------------------*/
.navbar {
	background-color: var(--primary);
	border-bottom: 10px solid var(--secondary-pink);
}

.brqubra {
	display: none;
}
.logo {
	color: #fff;
	font-weight: 500;
	font-size: 1rem;
}
.logo img {
	width: 40px;
}
.nomelog {
	vertical-align: middle;
	margin: 2px 0;
}
.fa-power-off {
	margin-right: 0.3rem;
}

.page-container {
	position: relative;
	min-height: 100vh;
}
.content-wrap {
	padding-bottom: 150px;
}
footer {
	background-color: var(--primary);
	position: absolute;
	bottom: 0;
	width: 100%;
	border-top: 10px solid var(--secondary-pink);
}
footer img {
	max-width: 150px;
}
footer .btn-link,
footer .btn-link:hover {
	color: #fff;
	font-weight: 600;
}

#divErro.alert {
	line-height: 2rem;
}
.sombra {
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.18);
}
.card.sombra {
	border: none;
}
.card-header {
	background-color: #fafafa;
}
.table {
	color: #555;
}

.btn-link:focus {
	box-shadow: 0 0 0 0.2rem rgba(255, 105, 118, 0.2);
}

.page-item.disabled .page-link {
	color: #ccc;
}
.page-item.active .page-link {
	color: #fff;
	background-color: var(--secondary-red);
	border-color: var(--secondary-red);
}
.page-link {
	color: #333;
}
.page-link:hover {
	color: #333;
	background-color: #e4e4e4;
}
.page-link:focus {
	box-shadow: none;
}

.modal-header .close {
	font-size: 1.2rem;
	font-weight: 400;
	line-height: 1.9rem;
}

.custom-control-inline {
	font-size: 1.1rem;
	line-height: 1.5rem;
	margin: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
	color: #fff;
	border-color: var(--secondary-red);
	background-color: var(--secondary-red);
}
.custom-control-input:focus ~ .custom-control-label::before {
	box-shadow: 0 0 0 0.2rem rgba(255, 33, 50, 0.25);
}
.form-control:focus,
.custom-select:focus {
	z-index: 2;
	border-color: var(--secondary-pink);
	box-shadow: 0 0 0 0.2rem rgba(255, 105, 118, 0.2);
}

.btn-link {
	color: var(--secondary-red);
}
.btn-link:hover {
	color: var(--secondary-red);
}

/*------------------------------------------------------------------
DADOS
------------------------------------------------------------------*/
h1 {
	font-size: 1.5rem;
	color: #545454;
	font-weight: 600;
}
.dados h4 {
	font-weight: 800;
	color: #555;
	font-size: 0.95rem;
}
.dados p {
	color: #666;
	margin-bottom: 0;
}
.bolo {
	position: absolute;
	bottom: 15px;
	right: 15px;
	color: var(--secondary-red);
}
.bolo.fa-2x {
	font-size: 1.5em;
}

/*------------------------------------------------------------------
BUSCADOR
------------------------------------------------------------------*/
.busca-title {
	font-size: 1.2rem;
	font-weight: 600;
	color: var(--primary);
}
.custom-control-inline {
	font-size: 1.1rem;
	line-height: 1.5rem;
	margin: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
	color: #fff;
	border-color: var(--secondary-red);
	background-color: var(--secondary-red);
}
.custom-control-input:focus ~ .custom-control-label::before {
	box-shadow: 0 0 0 0.2rem rgba(255, 33, 50, 0.25);
}
.form-control:focus {
	z-index: 2;
	border-color: var(--secondary-pink);
	box-shadow: 0 0 0 0.2rem rgba(255, 105, 118, 0.2);
}

.btn-coop {
	color: #fff;
	background-color: var(--primary);
	border-color: var(--primary);
}
.btn-coop:hover,
.btn-coop:active {
	color: #fff;
	background-color: var(--primary);
	border-color: var(--primary);
}
.btn-coop:focus {
	box-shadow: 0 0 0 0.2rem rgba(255, 105, 118, 0.2);
}

/*------------------------------------------------------------------
ABAS
------------------------------------------------------------------*/
#abas .nav-link {
	font-size: 1.5rem;
	padding: 0.6rem 1.2rem;
}
#abas .nav-link,
#abas .nav-link:hover {
	color: #333;
}
#abas .nav-link.active {
	font-weight: 800;
	color: var(--secondary-red);
}

#pills .nav-link {
	background-color: #fcefef;
}
#pills .nav-link {
	color: #333;
}
#pills .nav-link.active {
	font-weight: 800;
	color: #fff;
	background-color: var(--secondary-red);
	border-radius: 0.2rem;
}

/*------------------------------------------------------------------
SANFONA
------------------------------------------------------------------*/
.accordion .card-header {
	padding: 0;
}
.card-header .btn-accord.focus,
.card-header .btn-accord:focus {
	outline: 0;
	box-shadow: none;
}

.card-header .btn-accord {
	padding: 0.9rem 1.6rem;
}
.card-header .fa {
	margin-right: 10px;
}

.card.active .card-header {
	border-bottom: 3px solid var(--secondary-pink);
}
.card.active .btn-accord {
	font-weight: 800;
}

.btn-accord {
	font-weight: 400;
	color: var(--secondary-pink);
	text-decoration: none;
}
.btn-accord:hover {
	color: var(--secondary-red);
}

.card-title {
	font-size: 1.3rem;
	font-weight: 300;
	color: #333333;
}
.card-title strong {
	font-weight: 600;
}

.popover-header {
	font-weight: 500;
	color: #333333;
}
.card-text,
.popover-body {
	color: #666;
	font-size: 0.8rem;
}

.badge-danger {
	font-size: 1.1rem;
	font-weight: 600;
	background-color: var(--secondary-red);
	color: #fff;
}
.badge-light {
	font-size: 0.9rem;
	font-weight: 400;
	background-color: var(--secondary-red);
	color: #fff;
}

.estrelas {
	font-size: 1.5rem;
}
.estrelas i {
	color: #ccc;
}
#drogaria .estrelas.A i:nth-child(1) {
	display: initial !important;
	color: var(--secondary-red);
}
#drogaria .estrelas.A i:nth-child(2) {
	display: initial !important;
	color: var(--secondary-red);
}
#drogaria .estrelas.A i:nth-child(3) {
	display: initial !important;
	color: var(--secondary-red);
}
#drogaria .estrelas.B i:nth-child(1) {
	display: initial !important;
	color: var(--secondary-red);
}
#drogaria .estrelas.B i:nth-child(2) {
	display: initial !important;
	color: var(--secondary-red);
}
#drogaria .estrelas.B i:nth-child(3) {
	display: initial !important;
	color: #ccc;
}
#drogaria .estrelas.C i:nth-child(1) {
	display: initial !important;
	color: var(--secondary-red);
}
#drogaria .estrelas.C i:nth-child(2) {
	display: initial !important;
	color: #ccc;
}
#drogaria .estrelas.C i:nth-child(3) {
	display: initial !important;
	color: #ccc;
}
#geral .estrelas.A i:nth-child(1) {
	display: initial !important;
	color: var(--secondary-red);
}
#geral .estrelas.A i:nth-child(2) {
	display: initial !important;
	color: var(--secondary-red);
}
#geral .estrelas.A i:nth-child(3) {
	display: initial !important;
	color: var(--secondary-red);
}
#geral .estrelas.A i:nth-child(4) {
	display: initial !important;
	color: var(--secondary-red);
}
#geral .estrelas.B i:nth-child(1) {
	display: initial !important;
	color: var(--secondary-red);
}
#geral .estrelas.B i:nth-child(2) {
	display: initial !important;
	color: var(--secondary-red);
}
#geral .estrelas.B i:nth-child(3) {
	display: initial !important;
	color: var(--secondary-red);
}
#geral .estrelas.B i:nth-child(4) {
	display: initial !important;
	color: #ccc;
}
#geral .estrelas.C i:nth-child(1) {
	display: initial !important;
	color: var(--secondary-red);
}
#geral .estrelas.C i:nth-child(2) {
	display: initial !important;
	color: var(--secondary-red);
}
#geral .estrelas.C i:nth-child(3) {
	display: initial !important;
	color: #ccc;
}
#geral .estrelas.C i:nth-child(4) {
	display: initial !important;
	color: #ccc;
}
#geral .estrelas.D i:nth-child(1) {
	display: initial !important;
	color: var(--secondary-red);
}
#geral .estrelas.D i:nth-child(2) {
	display: initial !important;
	color: #ccc;
}
#geral .estrelas.D i:nth-child(3) {
	display: initial !important;
	color: #ccc;
}
#geral .estrelas.D i:nth-child(4) {
	display: initial !important;
	color: #ccc;
}

.alert-banner {
	background-color: #f4f4f4;
	font-weight: 600;
	font-size: 1.2rem;
	color: var(--primary);
	border-radius: 0;
	border: 1px solid #dae4f7;
}
.alert-banner img {
	max-width: 45px;
}
.alert-banner .close {
	font-size: 2.5rem;
	font-weight: 400;
}
.badge-danger{
	background-color: var(--secondary-red);
}
/*
------------------------------------------------------------------
MEDIAQUERIES
------------------------------------------------------------------
*/
@media (min-width: 650px) {
	.logo {
		font-size: 2rem;
		line-height: 1.8rem;
	}
	.logo img {
		width: 150px;
	}
}

@media (min-width: 768px) {
	h1 {
		font-size: 2rem;
	}
	.dados p {
		font-size: 1rem;
	}
}
@media (min-width: 992px) {
	.dados h4 {
		font-size: 1.2rem;
	}
	.bolo.fa-2x {
		font-size: 2em;
	}
	.perfil p {
		min-height: 120px;
	}
}
@media (min-width: 1200px) {
	.perfil p {
		min-height: 80px;
	}
}

@media (max-width: 768px) {
	.nomelog {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 160px;
		display: inline-block;
	}
}
@media (max-width: 500px) {
	.brqubra {
		display: block;
	}
	.alert-banner {
		font-size: 0.9rem;
		line-height: 1rem;
	}
}
@media (min-width: 430px) {
	.btn-coop:not(.reset) {
		padding-left: 2rem;
		padding-right: 2rem;
	}
}
